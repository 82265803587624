<template>
  <!-- 关于我们 -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">关于我们</div>
            <div class="backgroundImg2">ABOUT US</div>
          </div>
        </div>
      </header>
    </div>

    <!-- 简介 -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">企讯客简介</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

      <!--  -->
      <div class="introduce">
        <div class="introduce1">
          <div>
            公司于2018年获得全国通信资质牌照，自创立以来，公司始终坚持为客户创造最大价值的理念，升级推出赋能政企云通信的“企讯客通讯能力平台”。
          </div>
          <div style="margin: 26px 0">
            为企业和开发者提供语音能力、AI智能、短信能力、空号检测、SCRM、等一体化的软件服务。现已服务超过10000家的在网企业，获得客户一致认可是中国领先的企业云通信服务提供商。
          </div>
          <div>
            <div>通过与中国移动、中国联通、中国电信等运营商的紧密合作，四川企讯通通讯科技有限公司快速成为行业中领先的集通信资源、产品平台为一体的综合源头服务商。</div>
            <!-- <div>在云端/本地自由部署即可完成多渠道整合</div>
            <div>三重加密防护您的资料更加安全</div>
            <div>移动、电信、联通三网接入，让您的通话效果更有保障</div> -->
          </div>
        </div>
        <div class="introduce2" style="border:1px solid #959595;"></div>
      </div>
    </div>

    <!-- 发展历程 -->
    <div class="development" style="margin-bottom: 130px;">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">发展历程</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>
      <!--  历程  -->
      <div class="development1">
        <div class="" style="width: 405px">
          <div class="developmentLeft" style="">
            <!-- <div style="padding-top:10px;margin-left:10px;font-weight: bold;">2021年</div>
            <div style="margin-top:10px;margin-left:10px;">历时四年打造出《企讯客》全功能营销拓客管理系统即将上线，它不止是呼叫系统</div> -->
            <div style="padding-top:30px;margin-left:10px;">2022年1月，通讯能力平台上线，开放api对接，合作政企用户新增超过2000家</div>
            <!--<div class="developmentLeft1" style="margin-bottom: 8px; margin-right: 35px">
              2022年
            </div>
            <div class="developmentLeft2" style="margin-top: 8px; margin-right: 35px">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;历时四年打造出《企讯客》全功能营销拓客管理系统即将上线，它不止是呼叫系统
            </div>-->
          </div>
          <div class="developmentLeft" style="margin-top: 100px">
            <!-- <div style="padding-top:10px;margin-left:10px;font-weight: bold;">app/小程序</div>
            <div style="padding-top:10px;margin-left:10px;">新系统“云呼叫”测试上线，回拨、axb全面成功接入</div> -->
            <div style="padding-top:30px;margin-left:10px;">2020年08月，企讯客2.0版本上线（回拨系统+AI电销机器人融合成功）</div>
          </div>
          <div class="developmentLeft" style="margin-top: 100px">
            <!-- <div style="padding-top:10px;margin-left:10px;font-weight: bold;">AI上市</div>
            <div style="padding-top:10px;margin-left:10px;">自行研发的电话机器人上线运营</div> -->
            <div style="padding-top:30px;margin-left:10px;">2019年2月，正式推出企讯客1.0版本（回拨系统）</div>
          </div>
          <!-- <div class="developmentLeft" style="margin-top: 100px">
            <div style="padding-top:10px;margin-left:10px;font-weight: bold;">技术研发</div>
            <div style="padding-top:10px;margin-left:10px;">网络电话发展迅猛，研发自有呼叫中心软件</div>
          </div>
          <div class="developmentLeft" style="margin-top: 100px">
            <div style="padding-top:10px;margin-left:10px;font-weight: bold;">合作共赢</div>
            <div style="padding-top:10px;margin-left:10px;">陆续代理全国运营商相关通讯、增值业务</div>
          </div> -->
        </div>
        <div class="development2" style="width: 239px">
          <div style="margin-top: 15px; display: flex; align-items: center">
            <div class="navigation" style="margin: 0 12px 0 15px"></div>
            <div class="development2-1">
              <span class="development2-1-1">2022年1月</span>
            </div>
          </div>
          <div style=" width: 1px; height: 50px; background: #4DB0E4;margin: 8px auto 0;"></div>
          <div style=" margin-top: 11px; display: flex; align-items: center; justify-content: flex-end;">
            <div class="development2-1">
              <span class="development2-1-1">2021年9月</span>
            </div>
            <div class="navigation" style="margin: 0 15px 0 13px"></div>
          </div>
          <div style=" width: 1px; height: 50px; background: #4DB0E4; margin: 8px auto;"></div>
          <div style="margin-top: 15px; display: flex; align-items: center">
            <div class="navigation" style="margin: 0 12px 0 15px"></div>
            <div class="development2-1">
              <span class="development2-1-1">2020年08月</span>
            </div>
          </div>
          <div style=" width: 1px; height: 50px; background: #4DB0E4; margin: 8px auto;"></div>
          <div style=" margin-top: 11px; display: flex; align-items: center; justify-content: flex-end;">
            <div class="development2-1">
              <span class="development2-1-1">2019年10月</span>
            </div>
            <div class="navigation" style="margin: 0 15px 0 13px"></div>
          </div>
          <div style=" width: 1px; height: 50px; background: #4DB0E4; margin: 8px auto;"></div>
          <div style="margin-top: 15px; display: flex; align-items: center">
            <div class="navigation" style="margin: 0 12px 0 15px"></div>
            <div class="development2-1">
              <span class="development2-1-1">2019年2月</span>
            </div>
          </div>
          <div style=" width: 1px; height: 50px; background: #4DB0E4; margin: 8px auto;"></div>
          <div style=" margin-top: 11px; display: flex; align-items: center; justify-content: flex-end;">
            <div class="development2-1">
              <span class="development2-1-1">2018年</span>
            </div>
            <div class="navigation" style="margin: 0 15px 0 13px"></div>
          </div>
          <!-- <div style=" width: 1px; height: 50px; background: #ff6600; margin: 8px auto;"></div>
          <div style="margin-top: 15px; display: flex; align-items: center">
            <div class="navigation" style="margin: 0 12px 0 15px"></div>
            <div class="development2-1">
              <span class="development2-1-1">2013年</span>
            </div>
          </div>
          <div style=" width: 1px; height: 50px; background: #ff6600; margin: 8px auto;"></div>
          <div style=" margin-top: 11px; display: flex; align-items: center; justify-content: flex-end;">
            <div class="development2-1">
              <span class="development2-1-1">2010年</span>
            </div>
            <div class="navigation" style="margin: 0 15px 0 13px"></div>
          </div>
          <div style=" width: 1px; height: 50px; background: #ff6600; margin: 8px auto;"></div>
          <div style="margin-top: 15px; display: flex; align-items: center">
            <div class="navigation" style="margin: 0 12px 0 15px"></div>
            <div class="development2-1">
              <span class="development2-1-1">2007年</span>
            </div>
          </div>
          <div style=" width: 1px; height: 50px; background: #ff6600; margin: 8px auto;"></div>
          <div style=" margin-top: 11px; display: flex; align-items: center; justify-content: flex-end;">
            <div class="development2-1">
              <span class="development2-1-1">2006年</span>
            </div>
            <div class="navigation" style="margin: 0 15px 0 13px"></div>
          </div> -->
        </div>

        <!--  右边历程  -->
        <div class="" style="width: 405px">
          <div class="developmentLeft" style="margin-top: 100px;">
            <!-- <div style="padding-top:10px;margin-left:10px;font-weight: bold;">产品阵型</div>
            <div style="margin-top:10px;margin-left:10px;">与腾讯、阿里合作共赢，成为腾讯成都区企业微信合作商、阿里云服务合作商</div> -->
            <div style="padding-top:30px;margin-left:10px;">2021年9月，企讯客3.0版本重磅推出（回拨+AI机器人+人工呼叫+scrm大融合）</div>
          </div>
          <div class="developmentLeft" style="margin-top: 100px;">
            <!-- <div style="padding-top:10px;margin-left:10px;font-weight: bold;">云计算</div>
            <div style="margin-top:10px;margin-left:10px;">提高系统稳定型，上百台服务器打造出方大私有云</div> -->
            <div style="padding-top:30px;margin-left:10px;">2019年10月，获得工信部颁发全国多方通信业务牌照,赋能企业绿色云通信</div>
          </div>
          <div class="developmentLeft" style="margin-top: 100px;">
            <!-- <div style="padding-top:10px;margin-left:10px;font-weight: bold;">技术成型</div>
            <div style="margin-top:10px;margin-left:10px;">强大的技术团队专注于通信软件研发</div> -->
            <div style="padding-top:30px;margin-left:10px;">2018年公司成立，进军企业通讯领域，并与中国移动，中国联通和中国电信达成长期战略合作</div>
          </div>
          <!-- <div class="developmentLeft" style="margin-top: 100px;">
            <div style="padding-top:10px;margin-left:10px;font-weight: bold;">个人通讯</div>
            <div style="margin-top:10px;margin-left:10px;">全国建立100家以上代理渠道销售个人通讯产品</div>
          </div>
          <div class="developmentLeft" style="margin-top: 100px;margin-bottom: 30px;">
            <div style="padding-top:10px;margin-left:10px;font-weight: bold;">公司成立</div>
            <div style="margin-top:10px;margin-left:10px;">成都方大软件科技有限责任公司成立</div>
          </div> -->
        </div>
      </div>
    </div>

    <!-- 资质展示 -->
    <!-- <div>
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">资质展示</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

      <div class="exhibition">
        <div class="exhibition1">
          <div class="exhibition1-1">
            <el-image style="width: 100%; height: 100%" :src="require('@/assets/18关于我们-简介/License2.jpg')" :preview-src-list="srcList1"></el-image>
          </div>
          <div class="exhibition1-2">电信增值业务许可</div>
        </div>
        <div class="exhibition1">
          <div class="exhibition1-1">
            <el-image style="width: 100%; height: 100%" :src="require('@/assets/18关于我们-简介/License3.jpg')" :preview-src-list="srcList2"></el-image>
          </div>
          <div class="exhibition1-2">码号资源</div>
        </div>
        <div class="exhibition1">
          <div class="exhibition1-1">
            <el-image style="width: 100%; height: 100%" :src="require('@/assets/18关于我们-简介/License1.jpg')" :preview-src-list="srcList3"></el-image>
          </div>
          <div class="exhibition1-2">营业执照</div>
        </div>
        <div class="exhibition1">
          <div class="exhibition1-1">
            <el-image style="width: 100%; height: 100%" :src="require('@/assets/18关于我们-简介/License4.png')" :preview-src-list="srcList4"></el-image>
          </div>
          <div class="exhibition1-2">优秀企业称号</div>
        </div>
      </div>

    </div> -->

    <!-- <div>
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">客户分布</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>
      <div class="exhibition">
        <div id="mapData" style="width:1200px;height:700px;"></div>
      </div>
      <footer-top style="margin-top: 113px"></footer-top>
    </div> -->
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import footerTop from "@/components/footerTop.vue";
import Footer from "@/components/footer.vue";
import * as echarts from 'echarts';
import chinas from '../components/common/100000.json'
export default {
  name: "",
  components: {
    HeadNav,
    // footerTop,
    Footer,
  },
  data() {
    return {
      srcList1:[
        // require('@/assets/18关于我们-简介/营业执照二.jpg'),
      ],
      srcList2:[
        // require('@/assets/18关于我们-简介/营业执照三.jpg'),
      ],
      srcList3:[
        // require('@/assets/18关于我们-简介/营业执照一.jpg'),
      ],
      srcList4:[
        // require('@/assets/18关于我们-简介/营业执照四.png'),
      ],
      points: [
          {value: [116.8062, 30.9208],itemStyle:{color:'#d5b314'}}, //安徽
          {value: [115.8062, 28.2208],itemStyle:{color:'#d5b314'}}, //江西
          {value: [120.2062, 28.6208],itemStyle:{color:'#d5b314'}}, //浙江
          {value: [127.9688, 46.3688],itemStyle:{color:'#4fb6d2'}}, //黑龙江
          // {value: [110.3467, 41.4899],itemStyle:{color:'#52b9c7'}}, //内蒙古
          {value: [125.8154, 44.2584],itemStyle:{color:'#5abead'}}, //吉林
          // {value: [116.4551, 40.2539],itemStyle:{color:'#d5b314'}}, //北京
          {value: [117.4543, 24.9222],itemStyle:{color:'#d5b314'}}, //福建
          {value: [114.4995, 38.1006],itemStyle:{color:'#d5b314'}}, //河北
          {value: [117.1995, 36.0006],itemStyle:{color:'#d5b314'}}, //山东
          {value: [116.4219, 39.8189],itemStyle:{color:'#f58414'}}, //北京
          {value: [117.4219, 39.4189],itemStyle:{color:'#f58414'}}, //天津
          {value: [112.3352, 36.9413],itemStyle:{color:'#f58f0e'}}, //山西
          {value: [109.1162, 34.2004],itemStyle:{color:'#f5a305'}}, //陕西
          {value: [112.1162, 31.7004],itemStyle:{color:'#f5a305'}}, //湖北
          {value: [113.1162, 34.3004],itemStyle:{color:'#f5a305'}}, //河南
          // {value: [103.5901, 36.3043],itemStyle:{color:'#e7ab0b'}}, //甘肃
          // {value: [106.3586, 38.1775],itemStyle:{color:'#dfae10'}}, //宁夏
          // {value: [97.4038, 35.8207],itemStyle:{color:'#d5b314'}}, //青海
          {value: [102.9526, 29.7617],itemStyle:{color:'red'}}, //四川
          {value: [108.384366, 30.439702],itemStyle:{color:'#b9be23'}}, //重庆
          {value: [112.0823, 28.2568],itemStyle:{color:'#a6c62c'}}, //湖南
          // {value: [102.9199, 25.46639],itemStyle:{color:'#96cc34'}}, //云南
          {value: [106.713478,26.078343],itemStyle:{color:'#96cc34'}}, //贵州
          {value: [108.713478,23.078343],itemStyle:{color:'#96cc34'}}, //广西
          {value: [113.280637,23.125178],itemStyle:{color:'#96cc34'}}, //广东
          {value: [123.1238, 42.1216]}, 
      ],
      trajectoryData: [
        { coords: [[116.8062, 30.9208], [102.9526, 29.7617]], lineStyle: { color: '#4ab2e5' } }, 
        { coords: [[127.9688, 45.3688], [102.9526, 29.7617]], lineStyle: { color: '#4fb6d2' } }, 
        { coords: [[110.3467, 41.4899], [102.9526, 29.7617]], lineStyle: { color: '#52b9c7' } }, 
        { coords: [[125.8154, 44.2584], [102.9526, 29.7617]], lineStyle: { color: '#5abead' } }, 
        { coords: [[116.4551, 40.2539], [102.9526, 29.7617]], lineStyle: { color: '#f34e2b' } }, 
        { coords: [[123.1238, 42.1216], [102.9526, 29.7617]], lineStyle: { color: '#f56321' } }, 
        { coords: [[114.4995, 38.1006], [102.9526, 29.7617]], lineStyle: { color: '#f56f1c' } }, 
        { coords: [[117.4219, 39.4189], [102.9526, 29.7617]], lineStyle: { color: '#f58414' } }, 
        { coords: [[112.3352, 36.9413], [102.9526, 29.7617]], lineStyle: { color: '#f58f0e' } }, 
        { coords: [[109.1162, 34.2004], [102.9526, 29.7617]], lineStyle: { color: '#f5a305' } }, 
        { coords: [[103.5901, 36.3043], [102.9526, 29.7617]], lineStyle: { color: '#e7ab0b' } }, 
        { coords: [[106.3586, 38.1775], [102.9526, 29.7617]], lineStyle: { color: '#dfae10' } }, 
        { coords: [[97.4038, 35.8207], [102.9526, 29.7617]], lineStyle: { color: '#d5b314' } }, 
        { coords: [[117.4543, 24.9222], [102.9526, 29.7617]], lineStyle: { color: '#c1bb1f' } }, 
        { coords: [[108.384366, 30.439702], [102.9526, 29.7617]], lineStyle: { color: '#b9be23' } }, 
        { coords: [[112.0823, 28.2568], [102.9526, 29.7617]], lineStyle: { color: '#a6c62c' } }, 
        { coords: [[102.9199, 25.46639], [102.9526, 29.7617]], lineStyle: { color: '#96cc34' } },
        { coords: [[106.713478, 26.078343], [102.9526, 29.7617]], lineStyle: { color: '#96cc34' } },
        { coords: [[113.280637,23.125178], [102.9526, 29.7617]], lineStyle: { color: '#96cc34' } }
      ],
    };
  },
  mounted() {
    // this.timeChange();
    //this.init()
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    init() {
          let myEchartsss = echarts.init(document.getElementById("mapData"));
					var mapName = "china"
					echarts.registerMap(mapName, chinas);

					let option = {
            backgroundColor: '#F8F8F8',
            title: {
              text: '',
              textStyle: {    
                fontSize: 30,
              },
              top: 20,
              left: 'center',
            },
            geo: {
              map: mapName,
              aspectScale: 0.75, //长宽比
              zoom: 1.1,
              roam: false,
              label:{
                fontSize: 6,
              },
              itemStyle: {
                normal: {
                  areaColor: {
                    type: 'radial',
                    x: 0.5,
                    y: 0.5,
                    r: 0.8,
                    colorStops: [{
                      offset: 0,
                      color: '#09132c', //0% 的颜色
                    },{
                      offset: 1,
                      color: '#274d68', //100%处的颜色
                    }],
                    globalCoord: true 
                  },
                  shadowColor: 'rgb(58,115,192)',
                  shadowOffsetX: 5,
                  shadowOffsetY: 5
                },
                emphasis: {
                  areaColor: '#2AB8FF',
                  borderWidth: 0,
                  color: 'green',
                  label: {
                      show: false
                  }
                },
              },
              regions: [{
                name: '南海诸岛',
                itemStyle: {
                  areaColor: 'rgba(0, 10, 52, 1)',
                  borderColor: 'rgba(0, 10, 52, 1)',
                  normal: {
                    opacity: 0,
                    label: {
                      show: false,
                      color: "#009cc9"
                    }
                  }
                }
              }],
            },
            series: [{
              type: 'map',
              roam: false,
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    color: '#6DE2B2'
                  }
                },
                emphasis: {
                  textStyle: {
                    color: 'rgb(183,185,14)'
                  }
                }
              },
              itemStyle: {
                normal: {
                  borderColor: 'rgb(147, 235, 248)',
                  borderWidth: 1,
                  areaColor: {
                    type: 'radial',
                    x: 0.5,
                    y: 0.5,
                    r: 0.4,
                    colorStops: [{
                      offset: 0,
                      color: '#09132c' // 0% 处的颜色
                    },{
                      offset: 1,
                      color: '#274d68'  // 100% 处的颜色
                    }],
                    globalCoord: true // 缺省为 false
                  },
                },
                emphasis: {
                  areaColor: 'rgb(46,229,206)',
                  borderWidth: 0.1
                }
              },
              zoom: 1.1,
              map: mapName,
            },{
              type: 'effectScatter',
              coordinateSystem: 'geo',
              showEffectOn: 'render',
              zlevel: 1,
              rippleEffect: {
                period: 15,
                scale: 4,
                brushType: 'fill'
              },
              hoverAnimation: true,
              label: {
                normal: {
                  formatter: '{b}',
                  position: 'right',
                  offset: [15, 0],
                  color: '#1DE9B6',
                  show: true
                }
              },
              itemStyle: {
                normal: {
                  color: '#1DE9B6',
                  shadowBlur: 10,
                  shadowColor: '#333'
                }
              },
              symbolSize: 8,
              data: this.points
            },{
              type: 'lines',    //连线
              zlevel: 2,
              effect: {
                show: true,
                period: 6, //箭头指向速度，值越小速度越快
                trailLength: 0.2, //特效尾迹长度[0,1]值越大，尾迹越长重
                symbol: 'arrow', //箭头图标
                symbolSize: 5, //图标大小
              },
              lineStyle: {
                normal: {
                  color: '#1DE9B6',
                  width: 0.5, //线条宽度
                  opacity: 0.1, //尾迹线条透明度
                  curveness: 0.4 //尾迹线条曲直度
                }
              },
              data: this.trajectoryData
            }]
					};
					myEchartsss.setOption(option,true);
      },
  },
};
</script>

<style src="@/style/AboutUsIntroduce.css" scoped></style>
<style lang="scss" scoped></style>
