<template>
  <div class="" style="width: 100%; height300px;">
    <div class="solution">
      <div class="solutionBackgroundimg">
        <div class="solution-1">
          <div class="solution1">
            最长1个月的全功能免费试用，O成本体验企讯客智能云营销系统
          </div>
          <div class="solution2" @click="changesize">
            <span>我要试用</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import eventBus from '../config/eventBus.js'
export default {
  props: {},
  data() {
    return {
      
    };
  },
  watch: {},

  mounted() {
    // this.abcd()
  },
  created() {},

  methods: {
    // abcd() {
    //   for(var a = 1;a<=9;a++){
    //     for(var b = 0;b<=9;b++){
    //       for(var c = 0;c<=9;c++){
    //         if((a*a*a + b*b*b + c*c*c) == a.toString()+b.toString()+c.toString()){
    //           console.log(a.toString()+b.toString()+c.toString(),'1234567')
    //         }
    //       }
    //     }
    //   }
    // }
    changesize(){
        eventBus.$emit('add',true)
    }
  },
};
</script>

<style lang="scss">
.solution {
  width: 100%;
}
.solutionBackgroundimg {
  width: 100%;
  height: 300px;
  background-image: url("../assets/02首页版本二/beijing@2x.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.solution-1 {
  width: 1200px;
  margin: 0 auto;
  padding-top: 96px;
  text-align: center;
}
.solution1 {
  height: 24px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}
.solution2 {
  width: 200px;
  height: 40px;
  margin: 43px auto 0;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  span {
    height: 16px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 40px;
    color: #ff6600;
  }
}
</style>
